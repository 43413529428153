import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import { useState } from 'react';

import Button from '../Buttons/Button';

const Ellipse = styled(Box)({
  backgroundColor: 'var(--primary-green)',
  borderRadius: '100%',
  minHeight: '76px',
  width: '76px',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '85%',
});

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '48px 16px 16px',
  gap: '10px',
  background: 'var(--default-white)',
  position: 'absolute',
  bottom: '50%',
  left: '50%',
  width: 'fit-content',
  height: 'fit-content',
  boxShadow: '0px 0px 11px  rgb(136, 167, 188, 0.3)',
  borderRadius: '6px',
  transform: 'translate(-50%, 50%)',
});

// eslint-disable-next-line require-jsdoc
export default function PoliticasModal() {
  let aceite = true;
  if (typeof window !== 'undefined') {
    aceite = window.localStorage.getItem('politicas') !== 'true';
  }
  const [open, setOpen] = useState(aceite);
  const handleClose = () => {
    window?.localStorage.setItem('politicas', 'true');
    console.log(open);
    setOpen(false);
  };

  return (
    <Modal
      disableScrollLock
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      open={open}
    >
      <Content width={{ xs: '312px', sm: 'fit-content', lg: 'fit-content' }}>
        <Ellipse>
          <Image src="/img/ellipse.png" alt="cookie" width={50} height={47} />
        </Ellipse>
        <Grid
          className="open-sans--size16"
          width={{ xs: '280px', sm: '440px' }}
          style={{ color: '#5A646E' }}
        >
          Usamos cookies para personalizar e melhorar a sua experiência no site.
          Ao continuar navegando, você concorda com a nossa Política de Cookies
          e Privacidade.
        </Grid>
        <Button
          width={{ xs: '280px', sm: '440px' }}
          component={Grid}
          onClick={handleClose}
        >
          Concordar e fechar
        </Button>
      </Content>
    </Modal>
  );
}
